<template>
  <div class="border">
    <div class="title">{{ title }}</div>
    <div class="line"></div>
    <div ref="chart" class="chart"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts/core';
import { GridComponent, LegendComponent, TooltipComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

export default {
  name: "TrendChart",
  components: {

  },
  props: {
    title: {
      type: String,
      default: '总分趋势图',
    },
    data: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {};
  },
  watch: {
    data(newVal, oldVal) {
      this.initData();
    },
  },
  methods: {
    initData() {
      let xaxis = [];
      let dataA = [];
      let dataB = [];
      for (let i = this.data.length - 1; i >= 0; i--) {
        xaxis.push(this.data[i].TrendYear);
        dataA.push(this.data[i].TrendASingleTotal);
        dataB.push(this.data[i].TrendBSingleTotal);
      }
      echarts.use([GridComponent, LegendComponent, TooltipComponent, LineChart, CanvasRenderer]);
      var chart = echarts.init(this.$refs.chart);
      var option = {
        grid: {
          top: '40px',
          bottom: '40px',
          left: '40px',
          right: '40px'
        },
        tooltip: {
          show: false,
          trigger: 'axis'
        },
        legend: {
          selectedMode: false,
          data: ['A类考生', 'B类考生']
        },
        label: {
          show: true,
        },
        xAxis: {
          type: 'category',
          data: xaxis
        },
        yAxis: {
          type: 'value',
          interval: 5,
          min: function (value) {
            return value.min - 5
          },
          max: function (value) {
            return value.max + 5
          }
        },
        series: [{
          name: 'A类考生',
          type: 'line',
          color: '#0086FF',
          data: dataA
        },
        {
          name: 'B类考生',
          type: 'line',
          color: '#FF6600',
          data: dataB
        }]
      };
      chart.setOption(option);
    }
  },
  mounted() {

  }
};
</script>

<style scoped>
.border {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid #DCDCDC;
  border-radius: 5px;
}

.title {
  font-size: 14px;
  margin: 10px 10px;
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.line {
  width: 100%;
  border-bottom: 1px solid #DCDCDC;
}

.chart {
  width: 100%;
  flex: 1;
  margin-top: 10px;
}
</style>
