<template>
    <div :class="selected ? 'radio-button radio-button_checked' : 'radio-button'" @click.stop="onClick">{{ label }}</div>
</template>

<script>
export default {
    name: "RadioButton",
    components: {
    },
    inject: {
        RadioItemGroup: {
            default: null
        }
    },
    computed: {
        value: {
            get() {
                //获取时触发
                return this.RadioItemGroup.value;
            },
            set(val) {
                //赋值时触发
                this.RadioItemGroup.updateValue(val);
            }
        },
        selected: {
            get() {
                return this.value == this.name;
            },
        }
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
        };
    },
    methods: {
        onClick() {
            this.RadioItemGroup.updateValue(this.name);
            this.$emit('click');
        }
    },
    mounted() {

    }
};
</script>
  
<style scoped>
.radio-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #DCDCDC;
    border-radius: 5px;
    background-color: white;
    color: black;
    font-size: 14px;
    transition: all .3s;
    margin: 5px 10px;
    padding: 5px 10px;
}

.radio-button_checked {
    background-color: #FE5E03;
    color: white;
    transition: all .3s;
}
</style>
  