<template>
  <div class="border">
    <div class="content1">
      <div class="title">{{ title }}</div>
    </div>
    <div class="line"></div>
    <div class="content2">
      <div class="titleA">A类考生</div>
      <div class="titleB">B类考生 </div>
    </div>
    <div class="line"></div>
    <div class="content3">
      <van-grid :column-num="5" :border="false">
        <van-grid-item>
          <div class="header">年份</div>
        </van-grid-item>
        <van-grid-item>
          <div class="header">单科</div>
          <div class="header">(满分=100)</div>
        </van-grid-item>
        <van-grid-item>
          <div class="header">单科</div>
          <div class="header">(满分>100)</div>
        </van-grid-item>
        <van-grid-item>
          <div class="header">单科</div>
          <div class="header">(满分=100)</div>
        </van-grid-item>
        <van-grid-item>
          <div class="header">单科</div>
          <div class="header">(满分>100)</div>
        </van-grid-item>

        <template v-for="item in data">
          <van-grid-item class="year">{{ item.TrendYear }}</van-grid-item>
          <van-grid-item class="txt1">{{ item.TrendASingleEqual100 }}</van-grid-item>
          <van-grid-item class="txt1">{{ item.TrendASingleThan100 }}</van-grid-item>
          <van-grid-item class="txt2">{{ item.TrendBSingleEqual100 }}</van-grid-item>
          <van-grid-item class="txt2">{{ item.TrendBSingleThan100 }}</van-grid-item>
        </template>
      </van-grid>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrendTable",
  components: {

  },
  props: {
    title: {
      type: String,
      default: '单科分数',
    },
    data: {
      type: Array,
      default: [{
        TrendYear: '2023',
        TrendASingleEqual100: '10',
        TrendASingleThan100: '10',
        TrendBSingleEqual100: '10',
        TrendBSingleThan100: '10',
      }, {
        TrendYear: '2022',
        TrendASingleEqual100: '10',
        TrendASingleThan100: '10',
        TrendBSingleEqual100: '10',
        TrendBSingleThan100: '10',
      }, {
        TrendYear: '2021',
        TrendASingleEqual100: '10',
        TrendASingleThan100: '10',
        TrendBSingleEqual100: '10',
        TrendBSingleThan100: '10',
      }, {
        TrendYear: '2020',
        TrendASingleEqual100: '10',
        TrendASingleThan100: '10',
        TrendBSingleEqual100: '10',
        TrendBSingleThan100: '10',
      }, {
        TrendYear: '2019',
        TrendASingleEqual100: '10',
        TrendASingleThan100: '10',
        TrendBSingleEqual100: '10',
        TrendBSingleThan100: '10',
      }],
    }
  },
  data() {
    return {

    };
  },
  methods: {

  },
  mounted() {
  }
};
</script>

<style lang="less" scoped>
.border {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #DCDCDC;
  border-radius: 5px;
}

.content1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .title {
    font-size: 14px;
    margin: 10px;
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.content2 {
  width: 100%;
  display: flex;
  margin: 10px 0;

  .titleA {
    margin-left: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    color: #0086FF;
    font-size: 16px;
    font-weight: 700;
  }

  .titleB {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    color: #FF6600;
    font-size: 16px;
    font-weight: 700;
  }
}

.content3 {
  width: 100%;
  flex: 1;

  .header {
    font-size: 14px;
  }

  .year {
    font-size: 14px;
  }

  .txt1 {
    color: #0086FF;
    font-size: 14px;
  }

  .txt2 {
    color: #FF6600;
    font-size: 14px;
  }
}

.line {
  width: 100%;
  border-bottom: 1px solid #DCDCDC;
}

::v-deep .van-grid-item__content {
  padding: 12px;
}
</style>
