<template>
  <div>
    <el-image :src="require('@/assets/images/pc/nationalLine1.png')"></el-image>
    <el-image :src="require('@/assets/images/pc/nationalLine2.png')"></el-image>
    <div class="content">
      <div class="title-box">
        <el-image class="title-img" fit="contain" :src="require('@/assets/images/pc/nationalLine_left.png')"></el-image>
        <span class="title">国家线</span>
        <el-image class="title-img" fit="contain" :src="require('@/assets/images/pc/nationalLine_right.png')"></el-image>
      </div>
      <div class="year-box">
        <span class="year light" @click="onClick('041F5FEBEAAC4FA6A00AA5F4D543B177')">2024</span>
        <span class="year" @click="onClick('A7D3ECC7D5A74D1BB744ED9644A411D8')">2023</span>
        <span class="year" @click="onClick('4D4C73FC18024776AD3C3C16ADA24676')">2022</span>
        <span class="year" @click="onClick('D9C4372BED744D048AB5456E45C4D50E')">2021</span>
        <span class="year" @click="onClick('4DC54F2622EF4D4F8CCF841D5BA31AF3')">2020</span>
        <span class="year" @click="onClick('280AFF2207F94E51A8E7A491C88A46F2')">2019</span>
        <span class="year" @click="onClick('BAE4D30C92904221B6A9F3CC9A9226B6')">2018</span>
        <span class="year" @click="onClick('E27BEC0F8D324804B9824D27CF3DFD06')">2017</span>
        <span class="year" @click="onClick('FE7E022C05384DF9B8B80F58B1B81C83')">2016</span>
      </div>
      <span class="line"></span>
      <div class="title-box">
        <el-image class="title-img" fit="contain" :src="require('@/assets/images/pc/nationalLine_left.png')"></el-image>
        <span class="title">自划线高校复试分数线</span>
        <el-image class="title-img" fit="contain" :src="require('@/assets/images/pc/nationalLine_right.png')"></el-image>
      </div>
      <span class="line"></span>
      <div v-for="items in groups">
        <div class="grid-row">
          <div v-for="item1 in items" class="grid-col">
            <div class="grid-cell_name">{{ item1.name }}</div>
            <div class="grid-cell_year">
              <span v-for="(item2, index2) in item1.items" :class="index2 == 0 ? 'year light' : 'year'" @click="onClick(item2.guid)">{{ item2.year }}</span>
            </div>
          </div>
        </div>
        <span class="line"></span>
      </div>
      <div class="title-box">
        <el-image class="title-img" fit="contain" :src="require('@/assets/images/pc/nationalLine_left.png')"></el-image>
        <span class="title">国家线趋势图-学术硕士</span>
        <el-image class="title-img" fit="contain" :src="require('@/assets/images/pc/nationalLine_right.png')"></el-image>
      </div>
      <radio-button-group class="flex fill" v-model="value1" @change="onChange1">
        <radio-button label="哲学" name="哲学"></radio-button>
        <radio-button label="经济学" name="经济学"></radio-button>
        <radio-button label="法学" name="法学"></radio-button>
        <radio-button label="教育学" name="教育学"></radio-button>
        <radio-button label="文学" name="文学"></radio-button>
        <radio-button label="历史学" name="历史学"></radio-button>
        <radio-button label="理学" name="理学"></radio-button>
        <radio-button label="工学" name="工学"></radio-button>
        <radio-button label="农学" name="农学"></radio-button>
        <radio-button label="医学" name="医学"></radio-button>
        <radio-button label="军事学" name="军事学"></radio-button>
        <radio-button label="管理学" name="管理学"></radio-button>
        <radio-button label="艺术学" name="艺术学"></radio-button>
        <radio-button label="交叉学科" name="交叉学科"></radio-button>
        <radio-button label="体育学" name="体育学"></radio-button>
        <radio-button label="工学照顾专业" name="工学照顾专业"></radio-button>
        <radio-button label="中医类照顾专业" name="中医类照顾专业"></radio-button>
        <radio-button label="享受少数民族照顾政策的考生" name="享受少数民族照顾政策的考生"></radio-button>
      </radio-button-group>
      <div class="trend-box">
        <header-entry class="header-entry">{{ value1 }}</header-entry>
        <div class="trend-content">
          <trend-chart class="trend-chart" :title="value1 + '总分国家线趋势图'" :data="trentInfos1"></trend-chart>
          <trend-table class="trend-table" :title="value1 + '单科分数'" :data="trentInfos1"></trend-table>
        </div>
      </div>

      <div class="title-box">
        <el-image class="title-img" fit="contain" :src="require('@/assets/images/pc/nationalLine_left.png')"></el-image>
        <span class="title">国家线趋势图-专业硕士</span>
        <el-image class="title-img" fit="contain" :src="require('@/assets/images/pc/nationalLine_right.png')"></el-image>
      </div>
      <radio-button-group class="flex fill" v-model="value2" @change="onChange2">
        <radio-button label="金融、应用统计、税务、国际商务、保险、资产评估" name="金融、应用统计、税务、国际商务、保险、资产评估"></radio-button>
        <radio-button label="审计" name="审计"></radio-button>
        <radio-button label="法律（非法学）、法律（法学）、社会工作、警务" name="法律（非法学）、法律（法学）、社会工作、警务"></radio-button>
        <radio-button label="教育、汉语国际教育" name="教育、汉语国际教育"></radio-button>
        <radio-button label="应用心理" name="应用心理"></radio-button>
        <radio-button label="体育" name="体育"></radio-button>
        <radio-button label="翻译、新闻与传播、出版" name="翻译、新闻与传播、出版"></radio-button>
        <radio-button label="文物与博物馆" name="文物与博物馆"></radio-button>
        <radio-button label="建筑学、城市规划" name="建筑学、城市规划"></radio-button>
        <radio-button label="电子信息、机械、材料与化工、资源与环境、能源动力、土木水利、生物与医药、交通运输" name="电子信息、机械、材料与化工、资源与环境、能源动力、土木水利、生物与医药、交通运输"></radio-button>
        <radio-button label="农业、兽医、风景园林、林业" name="农业、兽医、风景园林、林业"></radio-button>
        <radio-button label="临床医学、口腔医学、公共卫生、护理、药学、中药学" name="临床医学、口腔医学、公共卫生、护理、药学、中药学"></radio-button>
        <radio-button label="中医" name="中医"></radio-button>
        <radio-button label="军事" name="军事"></radio-button>
        <radio-button label="工商管理" name="工商管理"></radio-button>
        <radio-button label="公共管理" name="公共管理"></radio-button>
        <radio-button label="会计" name="会计"></radio-button>
        <radio-button label="旅游管理" name="旅游管理"></radio-button>
        <radio-button label="图书情报" name="图书情报"></radio-button>
        <radio-button label="工程管理" name="工程管理"></radio-button>
        <radio-button label="艺术" name="艺术"></radio-button>
        <radio-button label="享受少数民族政策的考生" name="享受少数民族政策的考生"></radio-button>
      </radio-button-group>
      <div class="trend-box">
        <header-entry class="header-entry">{{ value2 }}</header-entry>
        <div class="trend-content">
          <trend-chart class="trend-chart" :title="value2 + '总分国家线趋势图'" :data="trentInfos2"></trend-chart>
          <trend-table class="trend-table" :title="value2 + '单科分数'" :data="trentInfos2"></trend-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderEntry from "@/components/PC/HeaderEntry";
import RadioButton from "@/components/PC/RadioButton";
import RadioButtonGroup from "@/components/PC/RadioButtonGroup";
import TrendChart from "@/components/PC/TrendChart";
import TrendTable from "@/components/PC/TrendTable";
import Tools from "@/tool";
import Config from "@/api/service";
import Manager from "@/manager";
export default {
  name: "PCModule",
  components: {
    HeaderEntry,
    RadioButton,
    RadioButtonGroup,
    TrendChart,
    TrendTable,
  },
  props: {
  },
  data() {
    return {
      value1: '哲学',
      value2: '金融、应用统计、税务、国际商务、保险、资产评估',
      trentInfos1: [],
      trentInfos2: [],
      list: [{
        name: '北京大学', items: [{
          year: '2024',
          guid: '28BDDF87E14A4885B39863060A7C7390'
        }, {
          year: '2023',
          guid: 'FD73C607E9A9457CBC48A4EE9F096AAE'
        }, {
          year: '2022',
          guid: '9047612F534A4EBF9E68F34744F6C8EA'
        }, {
          year: '2021',
          guid: 'F40ABD816FF4416EAF64D8B3CA565FF8'
        }, {
          year: '2020',
          guid: '06527957F0FC47CABCC875370287C915'
        }, {
          year: '2019',
          guid: 'BA356C245CBA47A5AB5F8DADC0FE6312'
        }]
      }, {
        name: '北京航空航天大学', items: [{
          year: '2024',
          guid: 'FCB1F10A1AFC4F878AC7DB5AF50AE5B2'
        }, {
          year: '2023',
          guid: 'FA44600E97D14B679A9E87FBD2E9AACF'
        }, {
          year: '2022',
          guid: 'CAA272F55AB44CCAB8C1DE1D25E5C434'
        }, {
          year: '2021',
          guid: '0D0F66A5ECF54A4BB07066B0B48A9243'
        }, {
          year: '2020',
          guid: '735DFC911EAB4AA584DDE42BE148A246'
        }, {
          year: '2019',
          guid: 'BE88B1D0391843ABA5C41058D7CBCC6A'
        }]
      }, {
        name: '北京理工大学', items: [{
          year: '2024',
          guid: '9A5314A7DACA4FF0B5B322ECD71B192B'
        }, {
          year: '2023',
          guid: 'C38E930F95524B8D814199450914B178'
        }, {
          year: '2022',
          guid: '37447311C8F1480B8110DDB1B8A17CDB'
        }, {
          year: '2021',
          guid: '1B40C5348E2C458E967223FEF267DF3C'
        }, {
          year: '2020',
          guid: '8DF0054344C041959C92B11E70818F7A'
        }, {
          year: '2019',
          guid: 'E9D848834A294188A793EF1A336209F9'
        }]
      }, {
        name: '北京师范大学', items: [{
          year: '2024',
          guid: '42F34C9786D1484BBE24D7BBBC594832'
        }, {
          year: '2023',
          guid: '1E5B0AC7C2ED42FCB8E2DADD5E95EA4D'
        }, {
          year: '2022',
          guid: '93A8E95418134673B2435D989E91731D'
        }, {
          year: '2021',
          guid: 'DF6B1718BE2D48CD8DD536490228CBB8'
        }, {
          year: '2020',
          guid: 'C3AC889483C3430098FDCC8910A0F455'
        }, {
          year: '2019',
          guid: 'D0D976F3C00746E3AFD8759F7482B983'
        }]
      }, {
        name: '大连理工大学', items: [{
          year: '2024',
          guid: '491D2A0B522D435B9A450BA935D616AF'
        }, {
          year: '2023',
          guid: '454B785E76934824BE87CB90DA3AA687'
        }, {
          year: '2022',
          guid: 'BB47B81A18DA4D9592A91381153D0AB3'
        }, {
          year: '2021',
          guid: '658D7B9519D849A0876BDDC81475C428'
        }, {
          year: '2020',
          guid: '99360DAE55AB4435AEC74C2E7D3A3769'
        }, {
          year: '2019',
          guid: '595027C05BC3433DB1A6633F75FF56A4'
        }]
      }, {
        name: '电子科技大学', items: [{
          year: '2024',
          guid: '532FEE9CA38A41B5A0466E59CEFCFA73'
        }, {
          year: '2023',
          guid: 'F3E579FCC8A54ECAAF853E8351B3187E'
        }, {
          year: '2022',
          guid: '01CFF431982A438683195559E5745510'
        }, {
          year: '2021',
          guid: '7F1F14B0A5CA493E84D29B695EE10C94'
        }, {
          year: '2020',
          guid: '7716A48D68334ACE9AE7794F6DA5EA31'
        }, {
          year: '2019',
          guid: '09CC20DCDDCB4B83B011AC9E5362F064'
        }]
      }, {
        name: '东北大学', items: [{
          year: '2024',
          guid: '465AEC3C8B67436DBEE5005A92222961'
        }, {
          year: '2023',
          guid: 'F396F0CE76C64C368FB536DBAA030A66'
        }, {
          year: '2022',
          guid: '92BC8BF5E3274B2A973FA453F3D4BB19'
        }, {
          year: '2021',
          guid: 'FE1ECFEBAE294B67A7AC7D5E09654912'
        }, {
          year: '2020',
          guid: '1A1DB980EACD4DCD8D41A5E43F084C8C'
        }, {
          year: '2019',
          guid: '913850CB11D44E179C9E44C0F7C61D38'
        }]
      }, {
        name: '东南大学', items: [{
          year: '2024',
          guid: 'DC383C712B214A31B64F42A1F1B4CCE5'
        }, {
          year: '2023',
          guid: '5604BFD5DF6D4BF0891B708BD22F4FFB'
        }, {
          year: '2022',
          guid: '0F9C4DC4B56E4414A5F7667E2AAFAAA3'
        }, {
          year: '2021',
          guid: 'E84AC9A649164E8BBBDA791AC57950B8'
        }, {
          year: '2020',
          guid: 'E4346E45B3FA4952856F3AB331ABDFD3'
        }, {
          year: '2019',
          guid: '241081B17F9549CF93A6BF256661C228'
        }]
      }, {
        name: '复旦大学', items: [{
          year: '2024',
          guid: 'DF7AF82BE5644E35B2AE60F6381E07CE'
        }, {
          year: '2023',
          guid: '45F5A14DF6FD4C08958B6CC4778DF299'
        }, {
          year: '2022',
          guid: '6FB511625E244B87AB8B125081405344'
        }, {
          year: '2021',
          guid: 'FEDA4879211943DBACFB4363A2050903'
        }, {
          year: '2020',
          guid: '307B9656F5D0467584B2F9183ECDDCA0'
        }, {
          year: '2019',
          guid: '63639C5803264AE8907BAC93A15A058E'
        }]
      }, {
        name: '哈尔滨工业大学', items: [{
          year: '2024',
          guid: '230EE67401674B1B824E99BB23F3D93F'
        }, {
          year: '2023',
          guid: 'EB88BA3B820C45F3A53C43B6E5738D77'
        }, {
          year: '2022',
          guid: 'EA88910259F745B384A22C63D04600D9'
        }, {
          year: '2021',
          guid: '1965D7C5EFC0413E8CB34210811A9C6F'
        }, {
          year: '2020',
          guid: '80AF754145A7436382DD56943E7AD4D4'
        }, {
          year: '2019',
          guid: 'E4BBF7420A90486DBB0967FCCC38B034'
        }]
      }, {
        name: '湖南大学', items: [{
          year: '2024',
          guid: '203B27D9EC4D4C3C8164C93FBDDA886F'
        }, {
          year: '2023',
          guid: '1F879F20151F409DA66CB48BF387CF9A'
        }, {
          year: '2022',
          guid: '5629AF9557914D45833F2668021C3CFD'
        }, {
          year: '2021',
          guid: '650026BAEE77463DB90691EA050277CC'
        }, {
          year: '2020',
          guid: 'E22BCCD4522542EE879E081B08B19904'
        }, {
          year: '2019',
          guid: '95C5798CDBAD46EB9A8F71780395816F'
        }]
      }, {
        name: '华南理工大学', items: [{
          year: '2024',
          guid: '3E19B52F40304897BA0B41FF954B5D1F'
        }, {
          year: '2023',
          guid: '910792F84F9142B2A301E1505AA81A58'
        }, {
          year: '2022',
          guid: '1BD1BDD8A36342D5B0E71D887B8962B1'
        }, {
          year: '2021',
          guid: '64A58CCEEA8C424BBE01644513E34BEB'
        }, {
          year: '2020',
          guid: 'C9D44BCB684B4D86AFB4F239F972C526'
        }, {
          year: '2019',
          guid: '0D988A4635984B4EA5C33B1D9C37FE24'
        }]
      }, {
        name: '华中科技大学', items: [{
          year: '2024',
          guid: '1C52E7F27F7249348029F0B74C595019'
        }, {
          year: '2023',
          guid: 'B7936920EEEF496A826221D4F516716D'
        }, {
          year: '2022',
          guid: '56324A38B8CB45C192171E01FF6E56D5'
        }, {
          year: '2021',
          guid: '45BDEED69AFE42DA8D89B87797425A3A'
        }, {
          year: '2020',
          guid: '5E876282219F4FCCBA267A8E3918D7FB'
        }, {
          year: '2019',
          guid: '03BE8134556244CDA087B39A3048DE4B'
        }]
      }, {
        name: '吉林大学', items: [{
          year: '2024',
          guid: 'F803539B3CF646949FCB0694327B43F7'
        }, {
          year: '2023',
          guid: 'B3CF2FD246144848BD57D98249122836'
        }, {
          year: '2022',
          guid: '1DF307396C354A1C8C71E33BDD66A393'
        }, {
          year: '2021',
          guid: '78407DA2DB744F1888DA98F8313A5E68'
        }, {
          year: '2020',
          guid: 'B3BE7B84D54741FBAA29D8F4776B4732'
        }, {
          year: '2019',
          guid: '35D358FEFA604DD7A00D6FAD6CD79C89'
        }]
      }, {
        name: '兰州大学', items: [{
          year: '2024',
          guid: 'A16AFB973E7B4152A223E42C2F63B3F6'
        }, {
          year: '2023',
          guid: '5EF88993040347C0ADA0B651A12E2DC9'
        }, {
          year: '2022',
          guid: 'C8A0DF5DA1994B4EB7046A78941DD1E5'
        }, {
          year: '2021',
          guid: '07E2358CD28D4EB481A299B29004C360'
        }, {
          year: '2020',
          guid: '0A45D3D805794236B9F45F265DDEE006'
        }, {
          year: '2019',
          guid: '2017BAB981854170A109C32DB9FE09EC'
        }]
      }, {
        name: '南京大学', items: [{
          year: '2024',
          guid: 'C28C6CBAB66046E0A50B5FDD7D761E57'
        }, {
          year: '2023',
          guid: '5AC13634E2E44E1A8F9A1CAAEEB58730'
        }, {
          year: '2022',
          guid: '1D67BA8D6BA340A0A4C373EDE9EE7424'
        }, {
          year: '2021',
          guid: '37A0E25015C14C81B318852CB42A7B6D'
        }, {
          year: '2020',
          guid: 'F83FD0F0282149AFB0D0BAEDC36C0A06'
        }, {
          year: '2019',
          guid: '33F06A2A3CAC4D0399EFAE7DD234E8D9'
        }]
      }, {
        name: '南开大学', items: [{
          year: '2024',
          guid: '24DE3A4DC5B844A095630873BE5CD6BC'
        }, {
          year: '2023',
          guid: '3A3DCBE31E25452B93F5F2A3A0877935'
        }, {
          year: '2022',
          guid: '2C18ED348D8E4F0D8EDD5E8971CF8C3A'
        }, {
          year: '2021',
          guid: '2CB8371962684D1096C85003CF71C091'
        }, {
          year: '2020',
          guid: '8784DBDF85F242948386C7F1EDA61BE5'
        }, {
          year: '2019',
          guid: 'C1D6C933693A4983B894655BE9F99837'
        }]
      }, {
        name: '清华大学', items: [{
          year: '2024',
          guid: 'E10B06E407A3429EB17D50628F94FB9E'
        }, {
          year: '2023',
          guid: 'CA6591912B4A4BB2AEF2F1761A20AA87'
        }, {
          year: '2022',
          guid: '5B9A1365C40542EC9DC6662199E869E5'
        }, {
          year: '2021',
          guid: '7EB6DD173AA44AADA42A3A2D7A051F91'
        }, {
          year: '2020',
          guid: 'C0D8800065724977B46008239C1A28B6'
        }, {
          year: '2019',
          guid: '7A5CE865B6994F89A822E2994EA10DA8'
        }]
      }, {
        name: '厦门大学', items: [{
          year: '2024',
          guid: '55D0181607064881B83AB9FA8ED52B77'
        }, {
          year: '2023',
          guid: '022FAE823E384D88847547D268C76A21'
        }, {
          year: '2022',
          guid: 'D78E225603ED44129D817F655BB22900'
        }, {
          year: '2021',
          guid: '869CEA5CD40346F9AEF303E77AD5D973'
        }, {
          year: '2020',
          guid: '74F20A89B0B741C49018DB392B43110D'
        }, {
          year: '2019',
          guid: '1C3CE3CA33F84E67844B31AA9F1B1D48'
        }]
      }, {
        name: '山东大学', items: [{
          year: '2024',
          guid: 'B5B5587B8FB149DAB4A0A67D1A5E09C5'
        }, {
          year: '2023',
          guid: 'ED69427D48764D8F8E991D4D16C85921'
        }, {
          year: '2022',
          guid: '2FD7A713CD524F16B4AC3E57C48ED5BE'
        }, {
          year: '2021',
          guid: '0DA31E4208BE454FADE0C5105711D549'
        }, {
          year: '2020',
          guid: 'A2927F25892B498EAB10C4FFC0AEA0F0'
        }, {
          year: '2019',
          guid: '737A01ED812E4DCFAA4B3B101636791D'
        }]
      }, {
        name: '上海交通大学', items: [{
          year: '2024',
          guid: '0F7F4134CA064858BD26BB3241CD6A9C'
        }, {
          year: '2023',
          guid: '9176709B16714EC29B798038EC280DD9'
        }, {
          year: '2022',
          guid: '2D5149D77D214318B826B80272C65FD1'
        }, {
          year: '2021',
          guid: '100FEF7A38AF488FA23B8F47EC7C8C7D'
        }, {
          year: '2020',
          guid: 'F21F90E2331248B5A5F00DDEB6273E68'
        }, {
          year: '2019',
          guid: '106C2FEEC289449EA671B388BD5C4EA8'
        }]
      }, {
        name: '四川大学', items: [{
          year: '2024',
          guid: '517BD1147D5D420AA0013880AEA814AE'
        }, {
          year: '2023',
          guid: '713DC9415FC9444F8C13CAB131E19513'
        }, {
          year: '2022',
          guid: '4510229364534223A32EB4D55AEAB178'
        }, {
          year: '2021',
          guid: 'EC3BDDC01E7B4138A61409C13EED5265'
        }, {
          year: '2020',
          guid: '2F440891E1DF44FE85439288C6FE707B'
        }, {
          year: '2019',
          guid: '782F01CCE0464FF08DCB89F0405C27BE'
        }]
      }, {
        name: '天津大学', items: [{
          year: '2024',
          guid: '7870CCE01E7646CAAD1911D3670E2D2D'
        }, {
          year: '2023',
          guid: 'FFA144D0320747F7BD7B958FCFE7191E'
        }, {
          year: '2022',
          guid: '0B35CEFCF2B947A582FBCCCA1FD49236'
        }, {
          year: '2021',
          guid: '087EE5E916324BADBF22909D4861CF11'
        }, {
          year: '2020',
          guid: '47F6DCAFC74D4B1A93A8683DEEB8B0E5'
        }, {
          year: '2019',
          guid: '661162C2B90744298B2A13F757BFAC94'
        }]
      }, {
        name: '同济大学', items: [{
          year: '2024',
          guid: 'FF1959BEFC2B45FE9173FB72C34D1F04'
        }, {
          year: '2023',
          guid: 'AFBE247D2FAA453D910130361A3AA238'
        }, {
          year: '2022',
          guid: '66084B3C26384E22B945DC43C1C12CA9'
        }, {
          year: '2021',
          guid: '54F5D4638EF547D2A29A5614CC705330'
        }, {
          year: '2020',
          guid: '162FD6F13F6D43AC9D168633062A54E6'
        }, {
          year: '2019',
          guid: '37F1187E18F14FC9B2EB05E2A9431EFA'
        }]
      }, {
        name: '武汉大学', items: [{
          year: '2024',
          guid: '75C977702FC44A7B97E8EA625DCEAB4A'
        }, {
          year: '2023',
          guid: 'A47B661B75BD40FCA864D8E398A6BD06'
        }, {
          year: '2022',
          guid: 'BA7682010B9E41388AD59EB68FFC41B7'
        }, {
          year: '2021',
          guid: '9A9F71FFB2EB4E15BBCEBCED8C9CD0DB'
        }, {
          year: '2020',
          guid: 'CFDE2DD060574688817563565285776A'
        }, {
          year: '2019',
          guid: '0865037450F9440B9B74E9EF771B30F5'
        }]
      }, {
        name: '西安交通大学', items: [{
          year: '2024',
          guid: '9D93FCEC40584A2C9989BE8D56F40A8E'
        }, {
          year: '2023',
          guid: '20D96243CCC8460B9F838A786E1B8624'
        }, {
          year: '2022',
          guid: 'D2ABCEFE4227488E9FF52C63A8D4729D'
        }, {
          year: '2021',
          guid: '64B954A3689B4506832E7A741830DEF6'
        }, {
          year: '2020',
          guid: '0DFD4C54A74146C9AEB9A75E2849A20C'
        }, {
          year: '2019',
          guid: '5637727A218644279B186F1102217493'
        }]
      }, {
        name: '西北工业大学', items: [{
          year: '2024',
          guid: 'CBA7B50EE5F34B1E919597C8950D7A7D'
        }, {
          year: '2023',
          guid: 'F65C5FA7A11B40629436D82F2AB51BFD'
        }, {
          year: '2022',
          guid: '97BC5C09EA2649B0AAE887030EBC4499'
        }, {
          year: '2021',
          guid: 'A8FDF3D4E1874739862F4A342E1F4ACF'
        }, {
          year: '2020',
          guid: 'A2920FA0D6C1441A98530D292847C3B7'
        }, {
          year: '2019',
          guid: 'EF0FCE278B5F4A7D9A7443C971D59F25'
        }]
      }, {
        name: '浙江大学', items: [{
          year: '2024',
          guid: '7FF5E28AA8CA446D8C57E71F1A47099C'
        }, {
          year: '2023',
          guid: 'F6F9F476C4484EFBB2683DF51EFC11B5'
        }, {
          year: '2022',
          guid: '30E4D3F56640409F90ABD25E67331979'
        }, {
          year: '2021',
          guid: 'B8278750200944D4A767787AED4C527E'
        }, {
          year: '2020',
          guid: '6ACE820C11BF4BE08EC4784336E70C4E'
        }, {
          year: '2019',
          guid: 'B9ACD38DAFE7441583E1B6F84175A942'
        }]
      }, {
        name: '中国科学技术大学', items: [{
          year: '2024',
          guid: 'BB42702B47124BD5B2A268F6B964C357'
        }, {
          year: '2023',
          guid: '36FDC387CA8949B99EE59301D1D650CC'
        }, {
          year: '2022',
          guid: 'A90C695C8FAB483890F7E69C7E562597'
        }, {
          year: '2021',
          guid: 'C2FD971E995C40A6B6D44F9698560133'
        }, {
          year: '2020',
          guid: '7365FE566E604AC9A607C3F9C80F5F2E'
        }, {
          year: '2019',
          guid: 'B94200D555A3407992C78BD753B202E6'
        }]
      }, {
        name: '中国农业大学', items: [{
          year: '2024',
          guid: '524510AED6DB4CCDB7BCB627D5F0F0F9'
        }, {
          year: '2023',
          guid: '219B2AD6C7B04E3A96E24D0C38B8FCDB'
        }, {
          year: '2022',
          guid: '2E9DCEF10B7D4791BB60B9D3BF832E1F'
        }, {
          year: '2021',
          guid: '943B3CE641D24C019D0DE0AC2A838C0C'
        }, {
          year: '2020',
          guid: '4AC24847907B47FEB1A5380F5FC6CB6A'
        }, {
          year: '2019',
          guid: 'AC16FDA01A50419997E0205BCD70DBAB'
        }]
      }, {
        name: '中国人民大学', items: [{
          year: '2024',
          guid: '8412B1E312BC451FA58EB3EDF834AF52'
        }, {
          year: '2023',
          guid: '74E8020941604E19B91B25CA11B83FEA'
        }, {
          year: '2022',
          guid: '373B74FB720B48A59F9A6958922450AC'
        }, {
          year: '2021',
          guid: '23C8796767FA427883B2D1CA9ECA7C46'
        }, {
          year: '2020',
          guid: '286807AAC2FD44B38F10FADA2F69489B'
        }, {
          year: '2019',
          guid: '37F16A682E064EBB8E8A492A18B2E224'
        }]
      }, {
        name: '中南大学', items: [{
          year: '2024',
          guid: '9696B84E88214CB98F86EAB15CB1B862'
        }, {
          year: '2023',
          guid: '742AD3DEC93D4D9AAF786747C6016188'
        }, {
          year: '2022',
          guid: '5FBEF06BFA044DC3B3060B3F73D4CE4B'
        }, {
          year: '2021',
          guid: 'BF8338F6492E4D51BFC893EA9833509A'
        }, {
          year: '2020',
          guid: '625A1A96C1D546F6A64BFD983CD991AB'
        }, {
          year: '2019',
          guid: '650A21FCF4254CC994C091D8072E5969'
        }]
      }, {
        name: '中山大学', items: [{
          year: '2024',
          guid: 'CCC842F1A7D24DC38C1DAEC1808A5CA0'
        }, {
          year: '2023',
          guid: '918AF882002C4768A409A086D5B6C6CC'
        }, {
          year: '2022',
          guid: 'B6545A9F26964E3F94B093A237D90AC6'
        }, {
          year: '2021',
          guid: '2ECCF6A03BBD4393A94B136FD34728AA'
        }, {
          year: '2020',
          guid: 'B1D42447ABD7405EAD6A7B1CC45CEDDA'
        }, {
          year: '2019',
          guid: 'C6D15C2BFB6E43C39E628D61B552B51C'
        }]
      }, {
        name: '重庆大学', items: [{
          year: '2024',
          guid: '54894A6106664F10A4CA55F3558F3B1A'
        }, {
          year: '2023',
          guid: 'D0ACE364CC47496997EA408B1BBFC9FC'
        }, {
          year: '2022',
          guid: '607C5A6345A84B2891418242CD523471'
        }, {
          year: '2021',
          guid: 'B21109A47002400A8054A07F127C76F5'
        }, {
          year: '2020',
          guid: '1623827E202F43AF97B848A4E3E0F783'
        }, {
          year: '2019',
          guid: 'DCC3622FDDE240128F6858D34624A817'
        }]
      }]
    };
  },
  computed: {
    groups() {
      var items = [];
      var item = [];
      for (var i = 0; i < this.list.length; i++) {
        item.push(this.list[i]);
        if (item.length == 3 || i == this.list.length - 1) {
          items.push(item);
          item = [];
        }
      }
      return items;
    }
  },
  methods: {
    //获取趋势数据-学术硕士
    getTrentInfos1() {
      var model = {
        TrendDegreeType: '学术硕士',
        TrendBelongcategory: this.value1,
      };
      this.$axios
        .post(Config.nationalLine.trendList, model)
        .then((res) => {
          this.trentInfos1 = res.data.Data.TrendInfos;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取趋势数据-专业硕士
    getTrentInfos2() {
      var model = {
        TrendDegreeType: '专业硕士',
        TrendBelongcategory: this.value2,
      };
      this.$axios
        .post(Config.nationalLine.trendList, model)
        .then((res) => {
          this.trentInfos2 = res.data.Data.TrendInfos;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //添加访问商品记录
    addVisitProductRecord() {
      Manager.addVisitProductRecord("国家线");
    },
    //点击链接
    onClick(guid) {
      this.$router.push({
        name: 'Article1',
        params: {
          proxyCode: this.$store.state.proxyCode,
          articleGuid: guid,
        }
      });
    },
    //选择学术硕士
    onChange1() {
      this.getTrentInfos1();
    },
    //选择专业硕士
    onChange2() {
      this.getTrentInfos2();
    },
  },
  mounted() {
    this.getTrentInfos1();
    this.getTrentInfos2();
    this.addVisitProductRecord();
  }
};
</script>
<style lang="less" scoped>
.content {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 18px;

  .title-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .title-img {
    height: 15px;
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    margin: 10px;
  }

  .grid-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .grid-col {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .grid-cell_name {
    width: 120px;
    font-size: 14px;
  }

  .grid-cell_year {
    padding: 0px;
  }

  .year-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
  }

  .year {
    cursor: pointer;
    margin: 0px 2px;
    font-size: 14px;
  }

  .year:hover {
    text-decoration: underline;
  }

  .light {
    color: #FE5E03;
  }

  .line {
    display: block;
    border-top: 1px solid #e6e6e6;
    margin: 10px 0;
  }

  .trend-box {
    width: 100%;
    height: 450px;
    border: 1px solid #DCDCDC;
    border-radius: 5px;
    margin: 10px;

    .header-entry {
      margin-left: 20px;
    }

    .trend-content {
      display: flex;
      height: calc(100% - 80px);

      .trend-chart {
        margin: 10px;
      }

      .trend-table {
        margin: 10px;
      }
    }
  }
}
</style>