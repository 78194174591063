<template>
    <div class="group">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "RadioButtonGroup",
    provide() {
        return {
            RadioItemGroup: this   //将组件本身的vue对象传递给下级
        };
    },
    model: {
        prop: 'value',
        event: 'update:value'
    },
    props: {
        value: {
            type: String,
            default: null
        }
    },
    watch: {
        value: {
            immediate: false,
            handler(val) {
                this.$emit("change", val);
            }
        },
    },
    methods: {
        updateValue(val) {
            this.$emit("update:value", val);
        }
    },
};
</script>

<style>
.group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
</style>